<template>
  <list-template
      :loading="loading"
      :total="total"
      :current-page="page"
      :table-data="tableData"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onChangePage="changeCurrentPage"
      @onSearch="onSearch"
      @onHandle="tableHandle"
      @onReset="search = null;onReset()"
      ref="table"
  >
  </list-template>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      loading: true,
      // 搜索框的选中数据
      search: null,
      // 数据总量
      total: 0,
      searchConfig: [
        {
          prop: "month",
          tag: "select",
          placeholder: "请选择检查年月",
          options: []
        },
        {
          prop: "knack_type",
          placeholder: "请选择学习习惯",
          tag: "select",
          options: [],
          label: 'name',
          value: 'type',
        },
        {
          prop: "status",
          placeholder: "请选择完成状态",
          tag: "select",
          options: [
            {label: "已完成", value: 1},
            {label: "未完成", value: 2}
          ]
        },
        {
          prop: "subject_id",
          placeholder: "请选择科目",
          tag: "select",
          options: [],
          label: 'name',
          value: 'subject_id',
          change: (val, search) => {
            if (search.school_id) {
              this.search = search
              let params = {
                subject_id: search.subject_id,
                school_id: search.school_id
              }
              if (search.grade_id) {
                params.grade_id = search.grade_id
              }
              this.$_axios2.get('api/common/school', {params}).then(res => {
                let data = res.data.data;
                this.searchConfig[6].options = data.teaching_data;
              })
            }
          }
        },
        {
          prop: 'school_id',
          tag: 'select',
          placeholder: '请选择校区',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            this.search.grade_id = ''
            this.search.teaching_id = ''
            let params = {
              school_id: search.school_id
            }
            if (search.subject_id) {
              params.subject_id = search.subject_id
            }
            this.$_axios2.get('api/common/school', {params}).then(res => {
              let data = res.data.data;
              this.searchConfig[5].options = data.grade;
              this.searchConfig[6].options = data.teaching_data;
              if (data.subject_data.length) {
                let boo = false
                data.subject_data.forEach(item => {
                  if (item.subject_id === search.subject_id) boo = true
                })
                if (!boo) {
                  this.search.subject_id = ''
                }
              } else {
                this.search.subject_id = ''
              }
            })
          }
        }, {
          prop: 'grade_id',
          tag: 'select',
          placeholder: '请选择年级',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            this.search.teaching_id = ''
            let params = {
              school_id: search.school_id,
              grade_id: search.grade_id
            }
            if (search.subject_id) {
              params.subject_id = search.subject_id
            }
            this.$_axios2.get('api/common/school', {params}).then(res => {
              let data = res.data.data;
              this.searchConfig[3].options = data.subject_data;
              this.searchConfig[6].options = data.teaching_data;
              if (data.subject_data.length) {
                let boo = false
                data.subject_data.forEach(item => {
                  if (item.subject_id === search.subject_id) boo = true
                })
                if (!boo) {
                  this.search.subject_id = ''
                }
              } else {
                this.search.subject_id = ''
              }
            })
          }
        }, {
          prop: 'teaching_id',
          tag: 'select',
          placeholder: '请选择班级',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            if (!search.subject_id) {
              this.search = search
              let params = {
                school_id: search.school_id,
                grade_id: search.grade_id,
                teaching_id: search.teaching_id,
              }
              this.$_axios2.get('api/common/school', {params}).then(res => {
                let data = res.data.data;
                this.searchConfig[3].options = data.subject_data;
                if (data.subject_data.length) {
                  let boo = false
                  data.subject_data.forEach(item => {
                    if (item.subject_id === search.subject_id) boo = true
                  })
                  if (!boo) {
                    this.search.subject_id = ''
                  }
                } else {
                  this.search.subject_id = ''
                }
              })
            }
          }
        },
        {
          prop: "keyword",
          placeholder: "请输入姓名搜索"
        },
      ],
      tableData: [],
      tableConfig: [
        {width: "80rem", label: '序号', type: 'number', textAlign: 'left', fixed: true},
        {prop: "knack_info", label: "学习习惯", fixed: true, minWidth: 100},
        {prop: "subject_info", label: "科目", fixed: true},
        {prop: "month", label: "检查年月", minWidth: 100},
        {prop: "teacher_info", label: "检查老师", minWidth: 100},
        {prop: "teaching_total", label: "学生人数", minWidth: 100},
        {prop: "task_total", label: "检查任务", minWidth: 100},
        {prop: "accomplish_total", label: "已完成",},
        {prop: "accomplish_no_total", label: "未完成"},
        {prop: "rate", label: "实时完成率", minWidth: 120},
        {prop: "teaching_info", label: "教学班级", minWidth: 100},
        {prop: "grade_info", label: "年级"},
        {prop: "school_info", label: "校区"},
        {prop: "status_name", label: "完成状态", fixed: 'right', minWidth: 100},
        {
          prop: "",
          label: "操作",
          fixed: 'right',
          handle: true,
          showOverflowTooltip: false,
          buttons: [{ type:"view", text:"详情" }]
        },
      ],
    }
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.commonInterface(); // 获取可筛选项
    this.getKnackType(); // 获取学习习惯类型
    this.getMonth()
    this.getData(); // 获取数据
  },
  methods: {
    ...mapMutations(["setPage"]),
    getMonth() {
      this.$_axios2('api/educational-routine/month-select').then(res => {
        this.searchConfig[0].options = res.data.data.map(item => {
          return {
            label: item,
            value: item
          }
        })
      })
    },

    commonInterface() {
      this.$_axios2.get('api/common/school').then(res => {
        if (res.data.status === 0) {
          this.searchConfig[3].options = res.data.data.subject_data;
          this.searchConfig[4].options = res.data.data.school;
        }
      })
    },
    getKnackType() {
      this.$_axios2.get('api/educational-routine/knack-list').then(res => {
        if (res.data.status === 0) {
          this.searchConfig[1].options = res.data.data;
        }
      })
    },
    // 获取列表基础数据
    getData() {
      this.loading = true
      this.$_axios2.get("/api/educational-routine/wont-list", {params: {...this.search, page: this.page}}).then(res => {
        let data = res.data.data
        if (!(Array.isArray(data))) {
          this.tableData = data.list
          this.total = data.page.total
        } else {
          this.tableData = []
        }
      }).catch().finally(() => {
        this.loading = false
      })
    },
    changeCurrentPage(e) {
      this.setPage(e)
      this.getData();
    },
    onSearch(val) {
      this.setPage(1);
      this.search = val
      this.getData();
    },
    // 表格后面操作被点击
    tableHandle(row, text) {
      this.$router.push({path: "./details", query: {id: row.id}});
    },

    onReset() {
      this.commonInterface()
      this.searchConfig[5].options = []
      this.searchConfig[6].options = []
    }
  }
}
</script>

<style scoped>

</style>
